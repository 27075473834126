import styled from "styled-components"

export const Styled404Page = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    > h1{
        font-size: 64px;
        text-align: center;
        font-family: 'Roboto';
    }
    @media only screen and (max-width: 947px){
        > h1{
            font-size: 42px;
        }
    }
`