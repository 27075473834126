/** @format */

import React from "react";
import GlobalLayout from "../components/layouts/GlobalLayout";
import HelmetTemplate from "../components/HelmetTemplate/HelmetTemplate";

import { Styled404Page } from "../components/404/Styled404Page";

const NotFoundPage = () => {
    return (
        <GlobalLayout>
            <HelmetTemplate title="404" desc="Strony nie znaleziono" />
            <Styled404Page>
                <h1>Nie ma strony o podanym linku</h1>
            </Styled404Page>
        </GlobalLayout>
    );
};

export default NotFoundPage;
